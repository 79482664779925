import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import JobList from '../components/JobList';

const Title = styled.h1`
  margin-bottom: 20px;
`;

const JobsByTag = ({ data, pageContext, location }) => {
  const { title, tag } = pageContext;
  const jobs = data.jobsByTag.edges;

  return (
    <Layout location={location}>
      <SEO title={`${tag}の募集`} />
      <Title>{title}</Title>
      <JobList jobs={jobs} />
    </Layout>
  );
};

export default JobsByTag;

export const pageQuery = graphql`
  query JobsByTag($slug: String!, $currentDate: Date!) {
    jobsByTag: allMarkdownRemark(
      sort: { fields: [frontmatter___createdDate], order: DESC }
      filter: {
        fileAbsolutePath: { glob: "**/content/companies/**/jobs/**.md" }
        frontmatter: { tags: { eq: $slug }, expiryDate: { gte: $currentDate } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            jobId
            title
            description
            tags
            company {
              fields {
                slug
              }
              frontmatter {
                name
                website
                location
                logo {
                  childImageSharp {
                    fluid(maxWidth: 50) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
